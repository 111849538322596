#error-body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: #f0f0f0;
  min-height: 100vh;
  margin: 0px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 1.15;

  h1 {
    font-weight: 400;
    font-size: 3.5rem;
    margin-top: 0;
    line-height: 1.2;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 768px) {
      font-size: 2.5rem;
    }
  }

  h2 {
    font-weight: 600;
    margin-top: 0;
    font-size: 1.375rem;

    @media screen and (max-width: 768px) {
      font-size: 1.25rem;
    }
  }
  h3 {
    margin-top: 0;
    font-weight: 600;
    font-size: 1.1875rem;

    @media screen and (max-width: 768px) {
      font-size: 1.1rem;
    }
  }

  a {
    color: #00509e;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    margin-top: 0.5rem;
    margin-bottom: 3rem;
    line-height: 1.5;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .lead {
    font-size: 1.25rem;
    font-weight: 400;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  #error-container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    color: #272833;
    height: 100%;
    width: 80%;
    min-width: 600px;
    min-height: 100vh;

    @media screen and (max-width: 600px) {
      min-width: auto;
      width: 100%;
    }
  }

  #error-header {
    display: flex;
    min-height: 50px;
    align-items: center;
    padding: 10px;
    border-bottom: 0.125rem solid #ddd;
  }

  #error-main {
    padding: 2rem;

    #sok-form {
      display: grid;
      grid-template-columns: 13rem 3rem;
      gap: 0;
      height: 44px;
      margin-bottom: 3rem;
    }

    #sok-input,
    #sok-button {
      width: 100%;
      padding: 0.5rem;
      border: 0;
      background-color: #efefef;
      font-size: 1rem;
      height: 2.5rem;
    }
  }

  #error-number-text {
    font-size: 15vw;
    font-weight: bold;
    color: #00509e;
    padding-right: 1rem;
    padding-left: 0.5rem;
    opacity: 0.25;
    float: right;
    font-family: "Open sans", Arial, sans-serif;

    @media screen and (max-width: 600px) {
      font-size: 100px;
    }
  }

  footer {
    background-color: #00509e;
    color: #fff;
    padding: 1rem 1rem 4rem 1rem;
    margin-top: auto;

    .horizontal-list {
      display: flex;
      align-items: center;
      padding: 0;
      gap: 0.8rem;

      @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: start;
        gap: 1rem;
      }
    }

    a {
      color: #fff;
      min-height: 44px;
    }

    ul {
      padding: 0;
      list-style-type: none;
    }

    .divider {
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
  }
}
